<template>
    <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{!isEdit? 'EDIT' : 'TAMBAH'}} RUANGAN</h5>
                </div>
                <form @submit.prevent="submitData()">
                    <div class="modal-body">
                        <div class="form-group row">
                            <label
                                for="example-text-input"
                                class="col-4 col-form-label"
                                >{{ $t("common.name") }} *:</label
                            >
                            <div class="col-7">
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="roomDisplay.name"
                                    placeholder="Nama Ruangan"
                                    v-validate="'required'"
                                    data-vv-as="Nama Ruangan"
                                    name="nama"
                                    :class="{
                                        'is-invalid': errors.has('nama')
                                    }"
                                />
                                <div
                                    v-show="errors.first('nama')"
                                    class="invalid-feedback"
                                >
                                    {{ errors.first("nama") }}
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label
                                for="example-text-input"
                                class="col-4 col-form-label"
                                >{{ $t("common.doctor") }} *:</label
                            >
                            <div class="col-7">
                                <doctor-select
                                    v-model="roomDisplay.dokter"
                                    :placeholder="'Tambah Dokter'"
                                    :defaultValue="isEdit"
                                    :null-select="true"
                                    :is-show-tujuan="true"
                                ></doctor-select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label
                                for="example-text-input"
                                class="col-4 col-form-label"
                                >Lantai *:</label
                            >
                            <div class="col-7">
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="roomDisplay.floor"
                                    placeholder="Lantai"
                                    v-validate="'required'"
                                    data-vv-as="Lantai"
                                    name="lantai"
                                    :class="{
                                        'is-invalid': errors.has('lantai')
                                    }"
                                />
                                <div
                                    v-show="errors.first('lantai')"
                                    class="invalid-feedback"
                                >
                                    {{ errors.first("lantai") }}
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label
                                for="example-text-input"
                                class="col-4 col-form-label"
                                >Section *:</label
                            >
                            <div class="col-7">
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="roomDisplay.section"
                                    placeholder="Section"
                                    name="section"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label
                                for="example-text-input"
                                class="col-4 col-form-label"
                                >Deskripsi :</label
                            >
                            <div class="col-7">
                                <vue-text-area
                                    v-model="roomDisplay.description"
                                    :name="'Pesan'"
                                    :placeholder="''"
                                ></vue-text-area>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-clean btn-bold btn-upper btn-font-md"
                            @click="clearData()"
                        >
                            {{ $t("button.close") }}
                        </button>
                        <button
                            type="submit"
                            class="btn btn-default btn-bold btn-upper btn-font-md"
                        >
                            {{ $t("button.save") }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import SweetAlertTemplate from "../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
import VueTextArea from "./../../_general/VueTextArea.vue"
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
import vueMultiSelect from "../../_select/VueMultiSelect";
import DoctorSelect from'./../../_select/Doktor.vue';
import RoomDisplay from "../../../model/room-display-model";
const blockUI = new BlockUI();
const RoomDisplayRepository = RepositoryFactory.get("roomDisplay");
const DoctorRepository = RepositoryFactory.get("doctor");
const SweetAlert = Vue.extend(SweetAlertTemplate);


export default {
    components: {
        DoctorSelect,
        vueMultiSelect,
        VueTextArea
    },
    data() {
        return {
            roomDisplay: new RoomDisplay(),
            isEdit: true
        };
    },
    props: {
        modalName: { type: String, default: "modal_room" },
        modalData: {}
    },
    provide() {
        return {
            $validator: this.$validator
        };
    },
    methods: {
        clearData: function() {
            $("#" + this.modalName).modal("hide");
            this.$emit("update:showModal", false);
        },
        updateData: function(room) {
            this.roomDisplay = Object.assign({}, room)
        },
        submitData: function() {
            var vx = this;

            this.$validator.validateAll().then(result => {
                if (result) {
                    if (vx.roomDisplay.id) {
                        var params = {
                            id: vx.roomDisplay.id,
                            name: vx.roomDisplay.name,
                            dokter: vx.roomDisplay.dokter ? vx.roomDisplay.dokter : null,
                            floor: vx.roomDisplay.floor,
                            section: vx.roomDisplay.section,
                            description: vx.roomDisplay.description,
                        };

                        let instance = new SweetAlert().showConfirm({
                            swalTitle: "Apakah Anda yakin?",
                            swalText: "Perubahan data Ruangan akan tersimpan.",
                            swalType: "info",
                            onConfirmButton: function() {
                                blockUI.blockModal();
                                vx.update(params);
                            },
                            onCancelButton: function() {
                                blockUI.unblockModal();
                            }
                        });
                    } else {
                        var params = {
                            name: vx.roomDisplay.name,
                            dokter: vx.roomDisplay.dokter ? vx.roomDisplay.dokter : null,
                            floor: vx.roomDisplay.floor,
                            section: vx.roomDisplay.section,
                            description: vx.roomDisplay.description,
                        };

                        let instance = new SweetAlert().showConfirm({
                            swalTitle: "Apakah Anda yakin?",
                            swalText: "Data Ruangan baru akan ditambahkan.",
                            swalType: "info",
                            onConfirmButton: function() {
                                blockUI.blockModal();
                                vx.create(params);
                            },
                            onCancelButton: function() {
                                blockUI.unblockModal();
                            }
                        });
                    }
                }
            });
        },
        async update(payload) {
            var vx = this;
            await RoomDisplayRepository.updateRoom(payload)
                .then(response => {
                    new Promise((resolve, reject) => {
                        vx.$emit("event", resolve);
                    }).then(response => {
                        let instance = new SweetAlert().showInfo({
                            swalTitle: "Berhasil!",
                            swalText:
                                "Perubahan data Ruangan berhasil disimpan.",
                            swalType: "success",
                            onConfirmButton: function() {
                                blockUI.unblockModal();
                                vx.$emit("event");
                                vx.clearData();
                            }
                        });
                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: "Perubahan data Ruangan gagal disimpan.",
                        swalType: "error",
                        onConfirmButton: function() {
                            blockUI.unblockModal();
                        }
                    });
                });
        },
        async create(payload) {
            var vx = this;

            await RoomDisplayRepository.createRoom(payload)
                .then(response => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Berhasil!",
                        swalText: "Data Ruangan baru berhasil ditambahkan.",
                        swalType: "success",
                        onConfirmButton: function() {
                            blockUI.unblockModal();
                            vx.$emit("event");
                            vx.clearData();
                        }
                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: "Data Ruangan baru gagal ditambahkan.",
                        swalType: "error",
                        onConfirmButton: function() {
                            blockUI.unblockModal();
                        }
                    });
                });
        },
    },
    mounted() {
        $("#" + this.modalName).modal();
        if (this.modalData) {
            this.updateData(this.modalData);
            this.isEdit = false
        }
    }
};
</script>
