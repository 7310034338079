export default function RoomDisplay() {
    return {
        id: null,
        deleteAt: null,
        description: null,
        dokter: null,
        name: null,
        section: '',
        floor: [],
    };
}
