<template>
  <div>
    <div class="kt-radio-inline">
      <label
        class="kt-radio"
        v-for="gender in genders"
        :key="gender.id"
      >
        <input
          type="radio"
          :name="name"
          :value="gender.id"
          v-model="selectedValue"
          v-validate="isValidate ? 'required' : ''"
          data-vv-as="Jenis Kelamin"
          :data-vv-scope="(scope != null) ? scope : null"
        />
        {{ gender.name }}
        <span></span>
      </label>
      <div v-if="genders.length == 0" class="skeleton skeleton-date"></div>
    </div>
    <div v-show="errors.first(validateName)" class="text-danger" >
        <small>{{ errors.first(validateName) }}</small>
    </div>
  </div>

</template>

<script>
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const GenderRepository = RepositoryFactory.get("gender");

export default {
  inject: ["$validator"],
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {type:Object},
    isValidate:{type: Boolean, default: false},
    scope: {type: String, default:null},
    name: {type: String, default:'jenisKelamin'}
  },
  data() {
    return {
      genders: [],
    }
  },
  computed: {
      selectedValue: {
            get () {
                return (this.value) ? this.value.id : null;
            },
            set () {
                var data = this.genders.find(x => x.id == event.target.value);
                this.$emit('change', data);
            }
      },
      validateName: function (){
          return (this.scope != null) ? `${this.scope}.${this.name}` : this.name
      }
  },
  mounted() {
    GenderRepository.get().then(response => {
      this.genders = response.data;
    });
  }
}
</script>
